import { render, staticRenderFns } from "./DeParaField.vue?vue&type=template&id=1af541a4&scoped=true&"
import script from "./DeParaField.vue?vue&type=script&lang=js&"
export * from "./DeParaField.vue?vue&type=script&lang=js&"
import style0 from "./DeParaField.vue?vue&type=style&index=0&id=1af541a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1af541a4",
  null
  
)

export default component.exports