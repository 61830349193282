<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="webhookForm" ref="form" method="post">
      <div class="row justify-content-md-center p-3">
        <div class="col-12 col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12" v-for="(campo, index) in formData" v-bind:key="index">
                  <div class="row">
                    <!--  Campo  -->
                    <div class="col-12 m-0 p-0 mt-4 mb-2">
                        <label>{{  $t('Campo')  }}
                        </label>
                        <v-select
                          label="id" name="formData.campo" :clearable="false" :searchable="false"
                          v-model="campo.campo" :options="camposList"
                          v-validate="{ required: true }"
                        >
                        </v-select>
                        <transition name="slide" mode="">
                          <small v-if="errors.first('formData.campo')" class="royalc-error-field">{{ errors.first('formData.campo') }}</small>
                        </transition>
                    </div>
                    <!-- De Para-->
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th width="45%">De</th>
                          <th width="45%">Para</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        <de-para-field v-for="(dePara, i) in campo.opcoes" v-bind:key="i" v-model="campo.opcoes[i]" :index="i" :opcoes="campo.opcoes" />
                      </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Cancelar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import DeParaField from '@/views/Integrations/Webhooks/DePara/DeParaField'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import OportunidadeService from '@/services/OportunidadeService'
import WebhookService from '@/services/WebhookService'

export default {
  name: 'WebhookForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('oportunidades.title') + ' - %s'
    }
  },
  data () {
    return {
      camposList: [
        'companhiaTransporte',
        'container',
        'destino',
        'modalidadePagamento',
        'origem'
      ],
      // Configuration to mount table of selected values
      formData: [
        {
          campo: 'origem',
          opcoes: [
            {
              de: null,
              para: null
            }
          ]
        }
      ],
      formErrors: {},
      isLoading: true,
      fullPage: true,
      submitted: false
    }
  },
  components: {
    Loading,
    DeParaField,
    vSelect
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true
    // Load basilares data

    let promises = [
    ]

    // If is edit populate values
    let id = this.$route.params.id | this.$route.query.id

    if (id) {
      promises.push(WebhookService.getDePara(id))
    }

    Promise.all(promises).then((values) => {
      // Edit Info
      if (id) {
        let integracao = values[0].data.data
        _this.formData = integracao
      }

      _this.isLoading = false
    }).catch(() => {
      _this.isLoading = false
    })
  },
  methods: {
    fileDownload (file) {
      this.isLoading = true
      let idOportunidade = this.$route.params.id
      OportunidadeService.getFile(idOportunidade, file.filename).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', file.originalFilename)
        global.document.body.appendChild(link)
        link.click()
        this.isLoading = false
      })
    },
    formatStatus (value) {
      if (!value && value !== 0) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.oportunidade.statusList.aguardando'),
          'color': '#ababab'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.oportunidade.statusList.trabalhando'),
          'color': '#ababab'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.oportunidade.statusList.disponivel'),
          'color': '#5ec65f'
        },
        {
          'id': 9,
          'label': this.$i18n.t('form.oportunidade.statusList.expirado'),
          'color': '#ababab'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    getOriginDestinationType (modalidadeProcesso) {
      if (modalidadeProcesso && modalidadeProcesso.id === 2) {
        // Marítimo
        return 1
      } else if (modalidadeProcesso && modalidadeProcesso.id === 1) {
        // Aéreo
        return 2
      } else if (modalidadeProcesso && modalidadeProcesso.id === 3) {
        // Aéreo
        return 3
      }
      // Padrão Maritimo
      return null
    },
    onCancel (e) {
      let _this = this

      _this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja cancelar.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (result) => {
        if (result) {
          _this.isLoading = true
          _this.$router.push({ name: 'WebhookDeParaIndex' })
        }
      })
    },
    displayErrors (errors) {
      // @TODO: display errors from server
      util.getFormErrors(errors)
      // let formatedErrors = util.getFormErrors(errors)
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('webhookForm').then(valid => {
        if (valid) {
          _this.isLoading = true

          let data = {
            fields: _this.formData
          }

          let id = this.$route.params.id
          if (id) {
            WebhookService.putDePara(id, data).then(response => {
              _this.$router.push({ name: 'WebhookDeParaIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
            }).finally(() => {
              _this.isLoading = false
            })
          } else {
            WebhookService.post(data).then(response => {
              _this.$router.push({ name: 'WebhookDeParaIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
            }).finally(() => {
              _this.isLoading = false
            })
          }
        } else {
          _this.$util.scrollToFirstError()
        }
      })
    },
    onSelectModality (val) {
      this.formData.tipoCarga = null
    }
  },
  computed: {
    showTipoCarga () {
      if (this.formData.modalidadeProcesso && this.formData.modalidadeProcesso.id === 2) {
        return true
      }

      return false
    },
    modalidadeProcessoId () {
      if (this.formData.modalidadeProcesso) {
        return this.formData.modalidadeProcesso.id
      }

      return null
    },
    tipoCargaId () {
      if (this.formData.tipoCarga) {
        return this.formData.tipoCarga.id
      }

      return null
    },
    originDestinationDisabled () {
      return !this.formData.modalidadeProcesso
    },
    unidadeMedidaDisabled () {
      // Se vazio então desabilitar
      if (!this.formData.modalidadeProcesso) {
        return true
      }

      if (this.formData.modalidadeProcesso.id === 2 && !this.formData.tipoCarga) {
        return true
      }

      return false
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;
  }
</style>
