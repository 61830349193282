<template>
  <tr>
    <td class="p-0 m-0">
      <custom-input
        v-model="value.de"
        name="formData.webhook"
        type="text"
        rootClassName="col-12 m-0 p-0 pl-3 pr-3"
        inputClassName="md-form-control m-0 p-0"
        :error="errors.first('formData.webhook') ? errors.first('formData.webhook') : ''">
      </custom-input>
    </td>
    <td class="p-0 m-0">
      <custom-input
        v-model="value.para"
        name="formData.webhook"
        type="text"
        rootClassName="col-12 m-0 p-0 pl-3 pr-3"
        inputClassName="md-form-control m-0 p-0"
        :error="errors.first('formData.webhook') ? errors.first('formData.webhook') : ''">
      </custom-input>
    </td>
    <td class="pt-0 pb-0 mt-0 mb-0">
      <a class="btn btn-link btn-sm" @click="removeItem()" tabindex="">
        <i class="text-primary  ti-trash font-20"></i>
      </a>
      <a class="btn btn-link btn-sm" @click="addItem()" tabindex="">
        <i class="text-primary fa fa-plus-circle font-20"></i>
      </a>
    </td>
  </tr>
</template>

<script>
// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

export default {
  name: 'DeParaField',
  metaInfo () {
    return {
    }
  },
  data () {
    return {
    }
  },
  components: {
    CustomInput
  },
  computed: {
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    opcoes: {
      type: Array,
      required: true
    },
    value: {
      type: [Object],
      description: 'Input value'
    }
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    addItem (index) {
      // Adicionar após
      let i = this.index + 1
      // Added empty field filter on began
      let item = {
        de: null,
        para: null
      }

      // TODO: Obter Campos
      this.opcoes.splice(i, 0, item)
    },
    removeItem () {
      let _this = this
      this.opcoes.splice(_this.index, 1)

      // Se for o último adiciona um vazio
      if (_this.opcoes.length <= 0) {
        // Added empty field filter on began
        let item = {
          de: null,
          para: null
        }

        // TODO: Obter Campos
        this.opcoes.splice(0, 0, item)
      }
    }
  },
  created () {
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }
</style>
